import "./styles.css";
import { useEffect } from "react";
import React from "react";

const DealsItemGames = ({ item, list }) => {
  useEffect(() => {
    // console.log(item.price);
    // console.log(item.priceSale);
  }, []);

  return (
    <>
      <div
        className={`product-item ${list ? "product-item__list" : " "} ${
          item.price > 99999 ? "product_none" : ""
        }`}
      >
        {list ? (
          ""
        ) : (
          <div className="product-img">
            <img
              className="product-img__image"
              src={`https:${item.logoPicture}?w=162&h=300&q=90&mode=scale`}
            />
          </div>
        )}

        <div className={`product-info ${list ? "product-info__list" : " "}`}>
          <h3>
            <a
              href={`https://www.microsoft.com/ru-ru/p/game/${item.gameId}`}
              target="_blank"
            >
              {item.name}{" "}
            </a>
          </h3>

          {/* Убрал "Товар недоступен для покупки" в display none если выполняется это условие  */}
          <div className="product-price">
            {item.price > 99999 ? (
              <span className="price">Товар недоступен для покупки</span>
            ) : (
              <>
                <div className="price_account">
                  {" "}
                  На новом аккаунте:{" "}
                  <span className={`price ${list ? "price__list" : " "}`}>
                    {item.price > item.priceUsa ? (
                      <>{Math.ceil(item.priceUsa / 10) * 10} рублей</>
                    ) : (
                      <>{Math.ceil(item.price / 10) * 10} рублей</>
                    )}
                    {/* {Math.ceil(item.price / 10) * 10} рублей{" "} */}
                    {/* ////// */}
                    {/* {item.country === "ars" ? (
                      <span style={{ fontSize: 5 }}>I</span>
                    ) : (
                      <span style={{ fontSize: 5 }}>II</span>
                    )} */}
                  </span>
                </div>

                <div className="price_account">
                  На ваш аккаунт:{" "}
                  <span className={`price ${list ? "price__list" : " "}`}>
                    {Math.ceil(item.priceUsa / 10) * 10} рублей{" "}
                  </span>
                </div>

                {/* Пока что нет скидок, чтоб настроить отображение */}
                {/* {item.priceSale < 9999 || item.priceSale === item.price ? (
                  ""
                ) : (
                  <span className={`price ${list ? "price__list" : " "}`}>
                    {Math.ceil(item.priceSale / 10) * 10} рублей{" "}
                    {item.countrySale === "ars" ? (
                      <span style={{ fontSize: 5 }}>I</span>
                    ) : (
                      <span style={{ fontSize: 5 }}>II</span>
                    )}
                  </span>
                )} */}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DealsItemGames;
