import "./styles.css";

import { useState, useEffect } from "react";
import React from "react";

const InstructionsPage = () => {
  useEffect(() => {}, []);

  return (
    <div>
      <div>Как нас не потерять</div>
    </div>
  );
};

export default InstructionsPage;
