export const donate = [
  {
    id: 39,
    nameGame: "Assassin's Creed Вальгалла",
    donate: [
      { name: "Helix (500)", id: "9NGJT528S39Z" },
      { name: "Helix (1050", id: "9PNFFP974368" },
      { name: "Helix (2300)", id: "9P1GXX6KGW55" },
      { name: "Helix (4200)", id: "9N4D2P89J2ND" },
      { name: "Helix (6600).", id: "9NG798NKLMH6" },
    ],
  },
  {
    id: 43,
    nameGame: "Assassin's Creed Odyssey",
    donate: [
      { name: "Helix (500)", id: "C2G09K45DT0Q" },
      { name: "Helix (1050", id: "BQNZLF2LTMTX" },
      { name: "Helix (2400)", id: "BWHKTJ76QPHP" },
      { name: "Helix (4600)", id: "BZ2L0H6L3GV8" },
      { name: "Helix (7400).", id: "BWDH09NPTNVN" },
      { name: "Helix (18000).", id: "C0N4RCX2CQPL" },
    ],
  },
  {
    id: 1,
    nameGame: "Apex Legends",
    donate: [
      { name: "500 монет", id: "9P6LX2D15SD7" },
      { name: "1 000 монет", id: "9N5QMZNW4BT1" },
      { name: "2k+150", id: "9NW3T901D9ZC" },
      { name: "4k+350", id: "9nrmpbw2twkj" },
      { name: "6k+700", id: "9p6pwp9q367c" },
    ],
  },

  {
    id: 24,
    nameGame: "Battlefield V",
    donate: [
      { name: "Валюта Battlefield: 1 050 ед", id: "C0CZNQPL0P9T" },
      { name: "Валюта Battlefield: 2 200 ед", id: "BSJGPQT3ZRFT" },
      { name: "Валюта Battlefield: 3500 ед", id: "C080F1L6DX27" },
      { name: "Валюта Battlefield: 6 000 ед", id: "C2H6SG5GCB67" },
      { name: "Валюта Battlefield: 12 500 ед", id: "9NNZBVP1TM14" },
    ],
  },

  {
    id: 41,
    nameGame: "Battlefield 2042",
    donate: [
      { name: "Battlefield 2042 - 500 BFC", id: "9NFJ6RTNL3GF" },
      { name: "Battlefield 2042 - 1100 BFC", id: "9P54FF0VQD7R" },
      { name: "Battlefield 2042 - 2400 BFC", id: "9NCJZN3LBD3P" },
      { name: "Battlefield 2042 - 5000 BFC", id: "9P9CLTVLLHD6" },
      { name: "Battlefield 2042 - 13000 BFC", id: "9NHXDFLDBN6G" },
    ],
  },

  {
    id: 42,
    nameGame: " Blood Bowl 3",
    donate: [
      { name: "Blood Bowl 3 - 2,225 Warpstone", id: "9P89KGJSGG15" },
      { name: "Blood Bowl 3 - 3,500 Warpstone", id: "9NJK66N43D63" },
      { name: "Blood Bowl 3 - 6,500 Warpstone", id: "9P5TT4F315KW" },
    ],
  },

  {
    id: 31,
    nameGame: "Call of Duty: Black Ops Cold War",
    donate: [
      { name: "1100 очков", id: "9P9MMKJ4Z178" },
      { name: "2400 очков", id: "9NF9H2D44NWX" },
      { name: "5000 очков", id: "9NT3673X9FK7" },
      { name: "9500 очков", id: "9P4RGDPXRBMQ" },
      // { name: "13000 очков", id: "9N3831K1C8ZF" },
      // { name: "21000 очков", id: "9PKGLSK8WCD4" },
    ],
  },

  {
    id: 25,
    nameGame: "Call of Duty: Vanguard",
    donate: [
      { name: "1100 очков", id: "9NPPDRKH0QRL" },
      { name: "2400 очков", id: "9NDCNLCRTBX5" },
      { name: "5000 очков", id: "9NBVX15Q09MQ" },
    ],
  },

  {
    id: 27,
    nameGame: "Call of Duty: Modern Warfare",
    donate: [
      { name: "1100 очков", id: "BNRGD8053J1C" },
      { name: "2400 очков", id: "BR458DC8416B" },
      { name: "5000 очков", id: "C4FQNNPTRQ8P" },
    ],
  },

  // {
  //   id: 26,
  //   nameGame: "Call of Duty: Warzone",
  //   donate: [
  //     { name: "1100 очков", id: "9NFC7WXN30RN" },
  //     { name: "2400 очков", id: "9NV491MN5CGX" },
  //     { name: "5000 очков", id: "9NX6PG17V0P0" },
  //   ],
  // },

  {
    id: 37,
    nameGame: "Call of Duty: Black Ops 6 или Call of Duty: Warzone",
    donate: [
      { name: "1,100", id: "9PMTHGB1Q3FD" },
      { name: "2,400", id: "9NHDTJ6T2G8B" },
      { name: "5,000", id: "9N1Q2GBW6LP0" },
      { name: "9,500", id: "9N3GKHJGNH27" },
      { name: "13,000", id: "9N52XS8ZX4L7" },
    ],
  },

  {
    id: 2,
    nameGame: "Dead by Daylight",
    donate: [
      { name: "6000 ЕД. АУРИТА", id: "C4VBHDZJNT7Q" },
      { name: "12500 ЕД. АУРИТА", id: "c35ppmwvmlct" },
    ],
  },

  {
    id: 8,
    nameGame: "Destiny 2",
    donate: [
      { name: "1000 (+100 бонусных)", id: "C2B3MCDF0C4L/GLLL" },
      { name: "2000 (+300 бонусных)", id: "C2B3MCDF0C4L/CLS5" },
      { name: "3000 (+500 бонусных)", id: "C2B3MCDF0C4L/CVSM" },
      { name: "5000 (+1000 бонусных)", id: "C2B3MCDF0C4L/5PHC" },
    ],
  },

  {
    id: 46,
    nameGame: "Diablo IV",
    donate: [
      { name: "500 Platinum", id: "9N7CX4BL33G5" },
      { name: "1000 Platinum", id: "9PL24NCS7JTS" },
      { name: "2800 Platinum", id: "9N03ZLKMPRNF" },
      { name: "5700 Platinum", id: "9N3HG5Q2KXBL" },
      { name: "11500 Platinum", id: "9PG48GLQKXWN" },
    ],
  },

  {
    id: 3,
    nameGame: "Fallout 76",
    donate: [
      { name: "1000 атомов (+100 бесплатно)", id: "BPLBMSPPPZ3Z" },
      { name: "2000 атомов (+400 бесплатно)", id: "BT0J49JZ9MV5" },
      { name: "4000 атомов (+1000 бесплатно)", id: "C0DXLGXJRKZQ" },
      // { name: "Fallout 1st 12-Month", id: "CFQ7TTC0L4DR/0002" },
    ],
  },

  {
    id: 5,
    nameGame: "Fall Guys",
    donate: [
      { name: "2800 Show-Bucks", id: "9npkcwwmfg9t" },
      { name: "5000 Show-Bucks", id: "9pn0xr2dd7s6" },
    ],
  },

  //использую id

  {
    id: 33,
    nameGame: "FIFA 24",
    donate: [
      { name: "100 FC Points", id: "9MV6JVJJXQ85" },
      { name: "500 FC Points", id: "9N62J17GR62Q" },
      { name: "1,050 FC Points", id: "9NVXDL27L1MX" },
      { name: "1,600 FC Points", id: "9NB8DS2CR9DM" },
      { name: "2,800 FC Points", id: "9P259HQK2RLS" },
      { name: "5,900 FC Points", id: "9PLW6493QKD1" },
      { name: "12,000 FC Points", id: "9NNTV8VQJG9T" },
    ],
  },

  {
    id: 36,
    nameGame: "FC 25 (FIFA 25)",
    donate: [
      { name: "FC Points 100", id: "9MVBKZJXMHLJ" },
      { name: "FC Points 500", id: "9MTL65LTR98W" },
      { name: "FC Points 1050", id: "9NDTPBHN8CXV" },
      { name: "FC Points 1600", id: "9N8HRJW7KFTW" },
      { name: "FC Points 2800", id: "9NCWFR72T354" },
      { name: "FC Points 5900", id: "9P3WLX492PLT" },
      { name: "FC Points 12000", id: "9PFL1JGR9DX6" },
      { name: "FC Points 18500", id: "9P629VCGG874" },
    ],
  },

  {
    id: 35,
    nameGame: "FOR HONOR",
    donate: [
      { name: "11 000 ед. Стали", id: "BTM1X8DLZL6Q" },
      { name: "25 000 ед. Стали", id: "BZV3554VBD07" },
      { name: "65 000 ед. Стали", id: "C3NGDX951BJ3" },
      { name: "150 000 ед. Стали", id: "BTFD9MWDQ4F2" },
    ],
  },

  {
    id: 6,
    nameGame: "Fortnite",
    donate: [
      { name: "1 000 В-баксов", id: "c0f5ht9nv86p" },
      { name: "2 800 В-баксов", id: "c4bpbtqg5c1b" },
      { name: "5 000 В-баксов", id: "c20fm0b4q9kc" },
      { name: "13 500 В-баксов", id: "bwd299hxcxqk" },
    ],
  },

  {
    id: 23,
    nameGame: "Grand Theft Auto",
    donate: [
      {
        name: "Платежная карта «Акула-бык»",
        id: "BZLD4T1BQT1P",
      },
      {
        name: "платежная карта «Белая акула»",
        id: "C3RTGZNXFTBH",
      },
      {
        name: "платежная карта «Акула-кит",
        id: "C3PFBM03RF7F",
      },
      {
        name: "платежная карта «Мегалодон»",
        id: "C2PN98HX8DSR",
      },

      {
        name: "платежная карта «Акула-бык» (Xbox Series X|S)",
        id: "9P16208KB5NH",
      },
      {
        name: "платежная карта «Белая акула» (Xbox Series X|S)",
        id: "9NVJH73H5WQG",
      },
      {
        name: "платежная карта «Акула-кит (Xbox Series X|S)»",
        id: "9NW65SKP4LZF",
      },
      {
        name: "платежная карта «Мегалодон» (Xbox Series X|S)",
        id: "9N29JQDXJSZ0",
      },
    ],
  },

  {
    id: 7,
    nameGame: "Halo Infinite",
    donate: [
      { name: "500 кредитов Halo", id: "9pddt96d1bp2" },
      { name: "1000 кредитов Halo", id: "9nvmhsh9c861" },
      { name: "2000 кредитов Halo + ещё 200 в подарок", id: "9pcvvjg31sn6" },
      { name: "5000 кредитов Halo + ещё 600 в подарок", id: "9p32n72rvm66" },
      {
        name: "10 000 кредитов Halo + ещё 1 500 в подарок",
        id: "9nhvqzf3nnf7",
      },
    ],
  },

  {
    id: 30,
    nameGame: "Hunt: Showdown 1896",
    donate: [
      { name: "300 Blood Bonds", id: "9N42DSFM10NJ" },
      { name: "1000 Blood Bonds", id: "9P8P4MMTDFCG" },
      { name: "2500 Blood Bonds", id: "9P2NFGCFH8JJ" },
      { name: "4000 Blood Bonds", id: "9NRK6VDRB0V2" },
      { name: "5000 Blood Bonds", id: "9N985S3ZCJJ9" },
    ],
  },

  {
    id: 9,
    nameGame: "NARAKA: BLADEPOINT",
    donate: [
      { name: "6 560 ЗОЛОТА", id: "9nrt0nc09p62" },
      // { name: "12 960 ЗОЛОТА", id: "9ppshcf7ljpj" },
    ],
  },

  {
    id: 40,
    nameGame: "NBA 2K24",
    donate: [
      { name: "5000 ВВ", id: "9N4BZ38JMWRL" },
      { name: "15000 ВВ", id: "9MWJNLFMZFTD" },
      { name: "35 000 ВВ", id: "9N1DB7VQWVTS" },
      { name: "75 000 ВВ", id: "9MZ7QXG7GR3K" },
      { name: "200 000 ВВ.", id: "9PNWM887L1WF" },
      { name: "450 000 ВВ.", id: "9MSSHKLLWC3Z" },
      { name: "700 000 ВВ.", id: "9N43Z004Q170" },
      ,
    ],
  },

  {
    id: 47,
    nameGame: "NHL 24",
    donate: [
      { name: "NHL POINTS 250", id: "9N7T4NFHH2X2" },
      { name: "NHL POINTS 500", id: "9NQ5WXPLSQM7" },
      { name: "NHL POINTS 1000+150", id: "9ND200X3R1CD" },
      { name: "NHL POINTS 2500+500", id: "9PMV1J0QS3T0" },
      { name: "NHL POINTS 5000+1250", id: "9NZ4H4MLCKD0" },
      { name: "NHL POINTS 10 000+3000", id: "9MVXS7QZVDBR" },
      { name: "NHL POINTS 15 000+5000", id: "9P8JHD0P741C" },
    ],
  },

  {
    id: 32,
    nameGame: "Neverwinter",
    donate: [
      { name: "1000 Neverwinter Zen", id: "BZ6L6MLKKWN3" },
      { name: "2000 Neverwinter Zen", id: "BW2851MBGZR0" },
      { name: "5300 Neverwinter Zen", id: "BQSJ8P4ZL2XP" },
      { name: "11000 Neverwinter Zen", id: "BRBVBD17BBS5" },
      // { name: "23000 Neverwinter Zen", id: "BS32JLJQSZH9" },
    ],
  },

  {
    id: 38,
    nameGame: "Overwatch 2",
    donate: [
      { name: "000 монет Overwatch", id: "9NZ4144B8SJP" },
      { name: "2000 + 200 монет Overwatch", id: "9P5V77KVKQDM" },
      { name: "5000 (+700 бонусних) монет", id: "9NGTND1JDFGT" },
      { name: "100 жетонов", id: "BNBLPQM9MZ19" },
      { name: "200 жетонов", id: "BX08K9MCF28D" },
      { name: "400 жетонов", id: "C47BZSJJ1NLG" },
      { name: "900 жетонов", id: "BRZD8S9X5VZD" },
      // { name: "2600 жетонов", id: "C591D983WMZS" },
    ],
  },

  {
    id: 10,
    nameGame: "PUBG",
    donate: [
      { name: "1000 G-Coin (+100 бонусных)", id: "BPPNV1ZZ84ZR" },
      { name: "2000 G-Coin (+300 бонусных)", id: "C0JP946PW1HR" },
      { name: "5000 G-Coin (+1000 бонусных)", id: "BWMCBZ5VMC9M" },
    ],
  },

  {
    id: 11,
    nameGame: "Red Dead Redemption 2",
    donate: [
      { name: "25 золотых слитков", id: "C4MKRGLZBCS1" },
      { name: "55 золотых слитков", id: "C25LNCHXQCQD" },
      { name: "150 золотых слитков", id: "BW64MFQ52PK5" },
      { name: "245 золотых слитков", id: "BWJRVRRD5KF9" },
      { name: "350 золотых слитков", id: "C4TVZB9BWQ9W" },
    ],
  },

  {
    id: 28,
    nameGame: "Rocket League",
    donate: [
      { name: "Credits x1100", id: "9P89VTXN7S3S" },
      { name: "Credits x3000", id: "9MXDLQWFC5B9" },
      { name: "Credits x6500", id: "9PB8V3MP80S7" },
      { name: "Esports Tokens x1200", id: "BW2ZK21SGBRW" },
      { name: "Esports Tokens x2500", id: "BXKHN7ZLTBRD" },
    ],
  },

  {
    id: 29,
    nameGame: "Rust Console Edition",
    donate: [
      { name: "1100 Rust Coins", id: "BQWW9XZXM3WN" },
      { name: "2250 Rust Coins", id: "9NN8L3JJ857F" },
      { name: "4600 Rust Coins", id: "9MT352M40TGM" },
      { name: "7800 Rust Coins", id: "9N5J2LD0SSHD" },
    ],
  },

  {
    id: 16,
    nameGame: "Sea of Thieves",
    donate: [
      { name: "1000 древних монет", id: "9PCW2GCGQ75C" },
      { name: "2550 древних монет ", id: "9N306KV44DM3" },
      { name: "4250 древних монет", id: "9mwpccn8bpgx" },
    ],
  },

  {
    id: 34,
    nameGame: "SMITE",
    donate: [
      { name: "400 Гемы", id: "BVKR14XGV9FL" },
      { name: "800 Гемы", id: "BVLN494L9PHM" },
      { name: "1500 Гемы", id: "BPN65N36F5RB" },
      { name: "2500 Гемы", id: "BSRM8VM9LNVR" },
      { name: "3500 Гемы", id: "BQKZWJVPFP5P" },
      { name: "8000 Гемы", id: "BV92Q3BZW684" },
    ],
  },

  {
    id: 12,
    nameGame: "Skyforge",
    donate: [
      { name: "24000 аргентов", id: "BZFCJBHKN9NX" },
      { name: "50 000 аргентов", id: "BQJR14TJ2C9M" },
      { name: "105 000 аргентов", id: "bvh2xf2814v9" },
    ],
  },

  {
    id: 13,
    nameGame: "The Elder Scrolls Online",
    donate: [
      { name: "750 Crowns", id: "bw2pz3t99z1z" },
      { name: "1500 Crowns", id: "c4smjg63g58m" },
      { name: "3000 Crowns", id: "bsvk1g4vn37q" },
      { name: "5500 Crowns", id: "bns90j4d3n56" },
      { name: "14000 Crowns", id: "bzl98mvqpnpl" },
      { name: "21000 Crowns", id: "BPNTCMQJWF9V" },
    ],
  },

  {
    id: 22,
    nameGame: "Tom Clancy's Ghost Recon® Breakpoint",
    donate: [
      { name: "1200 (+100) Ghost Coins", id: "BT98G6MPRG8B" },
      { name: "2400 (+400) Ghost Coins", id: "BPPCNJHHTKK5" },
      { name: "4800 (+1000) Ghost Coins", id: "BQKGS2FN5CQ8" },
      { name: "9600 (+2400) Ghost Coins", id: "BWQ1DPG56XCR" },
    ],
  },

  {
    id: 14,
    nameGame: "TOM CLANCY'S RAINBOW SIX SIEGE (R6)",
    donate: [
      { name: "2670 Пакеты кредитов", id: "btvx0b7m4krv" },
      { name: "4920 Пакеты кредитов", id: "brk7vngrfmjv" },
      { name: "7560 Пакеты кредитов", id: "bxkzc92s4fmc" },
      { name: "16000 (12000 + доп. 4000) КРЕДИТОВ R6", id: "c02d8zhzc9sr" },
    ],
  },

  {
    id: 15,
    nameGame: "Tom Clancy's The Division 2",
    donate: [
      { name: "1050 премиальных кредитов", id: "bw0g0q077w0r" },
      { name: "2250 премиальных кредитов", id: "bvq71tsnrzk7" },
      { name: "4100 премиальных кредитов", id: "btsc688d7tcn" },
    ],
  },

  {
    id: 45,
    nameGame: " VALORANT",
    donate: [
      { name: "475 VALORANT Points", id: "9NZ48BQBS7H0" },
      { name: "1000 VALORANT Points (50 Bonus + 950)", id: "9NS0MQ7WV5K6" },
      {
        name: "2050 VALORANT Points (150 Bonus + 1900)",
        id: "9P1XFKPTJ5PM",
      },
      { name: "3650 VALORANT Points (325 Bonus + 3325)", id: "9N3RVX4V5XFK" },
      { name: "5350 VALORANT Points (600 Bonus + 4750))", id: "9P1ZWNNF3J8D" },
      { name: "11000 VALORANT Points (1500 Bonus + 9500)", id: "9P91JHZCKX94" },
    ],
  },

  {
    id: 17,
    nameGame: "Vigor",
    donate: [
      { name: "Vigor - Opulent Leader", id: "9NN6JP6C1M1C" },
      { name: "Apocalypse Warlord", id: "9N6FRV7KJ582" },
      {
        name: "Dirty Rich Tycoon 4000 (+2000 BONUS) CROWNS ",
        id: "9plb54gj35bs",
      },
    ],
  },
  {
    id: 18,
    nameGame: "Warface",
    donate: [
      { name: "2500 кредитов", id: "bxpx6f2pf64j" },
      { name: "5000 кредитов", id: "c4c4s83g4vlf" },
      { name: "10 000 кредитов", id: "c3xqw5x9wg72" },
    ],
  },
  {
    id: 19,
    nameGame: "Warframe",
    donate: [
      { name: "1000 платины + Редкий мод", id: "bvl8k3m9f941" },
      { name: "2100 платины + Двойные Редкие моды", id: "c4k32vnxw9sw" },
      { name: "3210 платины + Тройные Редкие моды", id: "bx7mffvjlgts" },
    ],
  },
  {
    id: 20,
    nameGame: "World of Tanks",
    donate: [
      { name: "25k gold", id: "bzh4mzwn5nw9" },
      { name: "12k gold", id: "c23h7tr53535" },
      { name: "6500 gold", id: "BPGMZ59C1R9H" },
      { name: "3000 gold", id: "BV69RC5KNTZR" },
      // { name: "20+7 secret card", id: "br8w2q8j15vv" },
      // { name: "10+3 secret card", id: "brpb5rljdjtg" },
      // { name: "6+1 secret card", id: "BPFKJ2FZKZN0" },
      // { name: "25+5 personalnyh kart", id: "C14T3HLR3P76" },
      // { name: "10+2 personalnyh kart", id: "BS0F23XM7CPX" },
      // { name: "17+5 sectet kart", id: "BQW6FN7KSV3W" },
      // { name: "10+2 sectet kart", id: "BST12MVZD82D" },
    ],
  },

  {
    id: 21,
    nameGame: "World of Warships",
    donate: [
      { name: "2.750 Doblon", id: "BW3R9Z7RRFX3" },
      { name: "5.625 Doblon", id: "C2VP4JGG29SK" },
      { name: "20 500 дублонов", id: "bz8sf43gb100" },
      { name: "30 000 дублонов ", id: "bvtvgm9548fg" },
      { name: "47 000 дублонов", id: "c4g87gtjs29p" },
    ],
  },

  // {
  //   id: 44,
  //   nameGame: "XDefiant",
  //   donate: [
  //     { name: "500 XCoins", id: "9NH61B1CZBBD" },
  //     { name: "1,000 XCoins", id: "9PD5S4W5ZZL1" },
  //     { name: "2,100 XCoins", id: "9NQ1FJGHRJF8" },
  //     { name: "5,750 XCoins", id: "9NL1VGV3Z0P4" },
  //     { name: "12,000 XCoins", id: "9PG77WHVD0XN" },
  //   ],
  // },
];
