import { BrowserRouter, Routes, Route } from "react-router-dom";
import DealsHome from "../Deals/DealsHome";
import DealsHomeList from "../DealsList/DealsHomeList";
import DonateList from "../Donate/DonateUserInterface/DonateList";
import SearchGameDreamTeam from "../DreamTeamInterface/searchDreamTeam";
import SearchGame from "../search";
import SearchGameUser from "../UserInterface/searchUser";
import "./styles.css";
import DealsHomeGames from "../Deals/DealsHomeGames";
import PageTopPaid from "../Deals/PageTopPaid";
import SubscriptionsHome from "../Subscriptions/SubscriptionsHome";
import PageNewGames from "../Deals/PageNewGames";
import DealsHomeListDreamTeam from "../DealsListDreamTeam/DealsHomeListDreamTeam";
import HomePage from "../HomePage/HomePage";
import SearchGameUserMaster from "../UserInterfaceMaster/searchUserMaster";
import InstructionsPage from "../InstructionsPage";
// import HeaderTopLine from "../HomePage/Header/HeaderTopLine";

const Router = () => {
  return (
    <BrowserRouter>
      <div className="container">
        <nav style={{ marginTop: 10 }}>
          {/* <HeaderTopLine /> */}
          {/* <HeaderMiddleLine />
        <HeaderBottomLine /> */}
        </nav>

        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/master1" element={<SearchGame />} />
          <Route path="/master" element={<SearchGameUserMaster />} />
          <Route path="/xcalc" element={<SearchGameUser />} />
          <Route path="/drtm" element={<SearchGameDreamTeam />} />
          <Route path="/dealslistdrtm" element={<DealsHomeListDreamTeam />} />
          <Route path="/donate" element={<DonateList />} />
          <Route path="/topgames" element={<PageTopPaid />} />
          <Route path="/newgames" element={<PageNewGames />} />
          {/* <Route path="/deals" element={<DealsHome />} /> */}
          <Route path="/deals" element={<DealsHomeGames />} />
          <Route path="/dealslist" element={<DealsHomeList />} />
          <Route path="/subscriptions" element={<SubscriptionsHome />} />
          <Route path="/faq" element={<InstructionsPage />} />

          {/* <Route path="/subcategory/:id" element={<GamesPage />} /> */}
          {/* <Route path="/game/:id" element={<GamePage />} /> */}
          <Route path="*" element={"error"} />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default Router;
