import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  searchGamesList: [], // получаем список игр при вводе текста
  searchGamesListEn: [], // получаем список игр при вводе текста En
  searchGameId: "", //ID игры
  searchGameName: "", // Название игры
  searchGameImg: "",
  searchGamePriceAr: 0, // Цена в АРГ
  searchGamePriceTr: 0, // Цена в ТУР
  searchGamePriceUsa: 0, // Цена в USA
  searchGamePriceUah: 0, // Цена в URK
  // searchGamePriceEg: 0, // Цена в Египет
  // searchGamePriceNg: 0, // Цена в Nigeria

  searchGamePriceArSale: 0, // Цена в АРГ на скидке
  searchGamePriceTrSale: 0, // Цена в ТУР на скидке
  searchGamePriceUsaSale: 0, // Цена в USA на скидке
  searchGamePriceUahSale: 0, // Цена в UKR на скидке
  // searchGamePriceEgSale: 0, // Цена в Египет на скидке
  // searchGamePriceNgSale: 0, // Цена в Nigeria на скидке

  searchGameDescription: "", //описание игры
  searchProductType: "", //донат/не донат
  whatXbox: false, // проверка какой xbox
  isFetchingGamesInfo: false,
  isFetchingPriceTr: false,
  isFetchingPriceUsa: false,
  isFetchingPriceUah: false,
};

// получаем список игр при вводе текста
export const getSearchGamesList = createAsyncThunk(
  "SearchSlice/getSearchGamesList",

  //передаем ID при вызове функции на странице ,  , &page=${numberPage}

  async (textSearch, { rejectWithValue, dispatch }) => {
    // if (language === "ru") {
    //   const response = await axios.get(
    //     `https://www.microsoft.com/msstoreapiprod/api/autosuggest?market=ru-ru&clientId=7F27B536-CF6B-4C65-8638-A0F8CBDFCA65&sources=Microsoft-Terms%2CIris-Products%2CDCatAll-Products&filter=%2BClientType%3AStoreWeb&counts=5%2C1%2C5&query=${textSearch}`
    //   );
    //   dispatch(setSearchGamesList(response.data.ResultSets[0].Suggests));
    // }
    // if (language === "en") {
    //   const response = await axios.get(
    //     `https://www.microsoft.com/msstoreapiprod/api/autosuggest?market=en-us&clientId=7F27B536-CF6B-4C65-8638-A0F8CBDFCA65&sources=Microsoft-Terms%2CIris-Products%2CDCatAll-Products&filter=%2BClientType%3AStoreWeb&counts=5%2C1%2C5&query=${textSearch}`
    //   );
    //   dispatch(setSearchGamesList(response.data.ResultSets[1].Suggests)); //для английского поиска
    // }

    const response = await axios.get(
      `https://www.microsoft.com/msstoreapiprod/api/autosuggest?market=ru-ru&clientId=7F27B536-CF6B-4C65-8638-A0F8CBDFCA65&sources=Microsoft-Terms%2CIris-Products%2CDCatAll-Products&filter=%2BClientType%3AStoreWeb&counts=5%2C1%2C5&query=${textSearch}`
    );
    dispatch(setSearchGamesList(response.data.ResultSets[0].Suggests));

    // const responseEn = await axios.get(
    //   `https://www.microsoft.com/msstoreapiprod/api/autosuggest?market=en-us&clientId=7F27B536-CF6B-4C65-8638-A0F8CBDFCA65&sources=Microsoft-Terms%2CIris-Products%2CDCatAll-Products&filter=%2BClientType%3AStoreWeb&counts=5%2C1%2C5&query=${textSearch}`
    // );
    // dispatch(setSearchGamesList(responseEn.data.ResultSets[1].Suggests));
    //для английского поиска

    // let responseAll = [
    //   ...response.data.ResultSets[0].Suggests,
    //   ...responseEn.data.ResultSets[1].Suggests,
    //   ...responseEn.data.ResultSets[0]?.Suggests,
    // ];

    // const uniqueArray = responseAll.filter(
    //   (item, index, self) =>
    //     index ===
    //     self.findIndex((i) => i.Metas[0].Value === item.Metas[0].Value)
    // );
    // dispatch(setSearchGamesList(responseAll));

    // Диспатчем то что пришло в переменную и записиываем в наш массив
  }
);

export const getSearchGamesListEn = createAsyncThunk(
  "SearchSlice/getSearchGamesListEn",

  //передаем ID при вызове функции на странице ,  , &page=${numberPage}

  async (textSearch, { rejectWithValue, dispatch }) => {
    const response = await axios.get(
      `https://www.microsoft.com/msstoreapiprod/api/autosuggest?market=en-us&clientId=7F27B536-CF6B-4C65-8638-A0F8CBDFCA65&sources=Microsoft-Terms%2CIris-Products%2CDCatAll-Products&filter=%2BClientType%3AStoreWeb&counts=5%2C1%2C5&query=${textSearch}`
    );
    // dispatch(setSearchGamesList(response.data.ResultSets[1].Suggests));
    //для английского поиска
    dispatch(setSearchGamesListEn(response.data.ResultSets[1].Suggests)); //для английского поиска

    // Диспатчем то что пришло в переменную и записиываем в наш массив
  }
);

export const getSearchGamesInfo = createAsyncThunk(
  "SearchSlice/getSearchGamesInfo",
  //передаем ID при вызове функции на странице ,  , &page=${numberPage}
  async (id, { rejectWithValue, dispatch }) => {
    const response = await axios.get(
      `https://displaycatalog.mp.microsoft.com/v7.0/products?bigIds=${id}&market=ar&languages=ru-ru`
    );

    // ищем постер из всех фото
    let image = response.data.Products[0].LocalizedProperties[0].Images.find(
      (item) => item.ImagePurpose === "Poster"
    );
    dispatch(setSearchGameImg(image.Uri));

    // Диспатчем то что пришло в переменную и записиываем в наш массив
    dispatch(
      setSearchGameName(
        response.data.Products[0].DisplaySkuAvailabilities[0].Sku
          .LocalizedProperties[0].SkuTitle
      )
    );

    dispatch(setsearchProductType(response.data.Products[0].ProductType));

    dispatch(
      setSearchGameDescription(
        response.data.Products[0].LocalizedProperties[0].ShortDescription
      )
    );

    dispatch(
      setWhatXbox(
        response.data.Products[0].Properties.PackageFamilyName?.includes(
          "Xbox360"
        )
      )
    );

    dispatch(
      setSearchGamePriceAr(
        response.data.Products[0].DisplaySkuAvailabilities[0].Availabilities[0]
          .OrderManagementData.Price.ListPrice
      )
    );

    dispatch(
      setSearchGamePriceArSale(
        response.data.Products[0]?.DisplaySkuAvailabilities[0]
          ?.Availabilities[1]?.OrderManagementData?.Price?.ListPrice ?? 0
      )
    );
    dispatch(setSearchGameId(id));
  }
);

export const getSearchGamesInfoPriceTr = createAsyncThunk(
  "SearchSlice/getSearchGamesInfoPriceTr",
  //передаем ID при вызове функции на странице ,  , &page=${numberPage}
  async (id, { rejectWithValue, dispatch }) => {
    //передача с параметром
    // const response = await axios.get(
    //   `https://emerald.xboxservices.com/xboxcomfd/contextualStore/productDetails/${id}?locale=tr-TR`,
    //   {
    //     headers: {
    //       "X-S2s-Authorization":
    //         "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6IjVCM25SeHRRN2ppOGVORGMzRnkwNUtmOTdaRSIsImtpZCI6IjVCM25SeHRRN2ppOGVORGMzRnkwNUtmOTdaRSJ9.eyJhdWQiOiJhcGk6Ly9iZjRhMzdhMC04ZDU5LTQ4YzYtYjliZi04MGNiZjI5NGRlZTkiLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC83MmY5ODhiZi04NmYxLTQxYWYtOTFhYi0yZDdjZDAxMWRiNDcvIiwiaWF0IjoxNzA1Njk4Nzg1LCJuYmYiOjE3MDU2OTg3ODUsImV4cCI6MTcwNTc4NTQ4NSwiYWlvIjoiRTJWZ1lKRDZvSDRyM2JGOC9aL0h1elovK2hHWEF3QT0iLCJhcHBpZCI6ImJmNGEzN2EwLThkNTktNDhjNi1iOWJmLTgwY2JmMjk0ZGVlOSIsImFwcGlkYWNyIjoiMSIsImlkcCI6Imh0dHBzOi8vc3RzLndpbmRvd3MubmV0LzcyZjk4OGJmLTg2ZjEtNDFhZi05MWFiLTJkN2NkMDExZGI0Ny8iLCJvaWQiOiI1MzQ3Mjk5ZC05ODk3LTQ0NjAtYjM4Yy03YWJlODg4MWRkNGMiLCJyaCI6IjAuQVJvQXY0ajVjdkdHcjBHUnF5MTgwQkhiUjZBM1NyOVpqY1pJdWItQXlfS1UzdWthQUFBLiIsInN1YiI6IjUzNDcyOTlkLTk4OTctNDQ2MC1iMzhjLTdhYmU4ODgxZGQ0YyIsInRpZCI6IjcyZjk4OGJmLTg2ZjEtNDFhZi05MWFiLTJkN2NkMDExZGI0NyIsInV0aSI6InAyelFKMkE1bGsyOW9OMFpDZk5pQUEiLCJ2ZXIiOiIxLjAifQ.e_PxXDdL2TDPwPE324TroGNUcKQT9nzXz4-QzFjXuGm3_PYCyRi2ZdwVbDa5srcsqFpi5oqMnwaNAVaEHifJDlKVMWzMKeZ7oIWeCWTDIX8UFWpdX8t4Bi3VfkIiQUctmhqG-9hYFEK8tN-E6Fsl6gSlKIqAF-MnKaai_e3QKHdydudgtIMLx1ZFiWYa9JHEyMmru8TINbKpBs2ybn4s65wK7ICqdVHuqDD0nzChl2b6LcB6IFbeI-ryed5FYJrhZtMeNX7SDaLp9dn_NAg83qq0UOiygEyjXuBAFUgVUJVfeZqu3e1aVanFR0zQKT5KauZP8U8-C_c_9EKRRIan9A",
    //     },
    //   }
    // );
    //передача с параметром

    const response = await axios.get(
      `https://displaycatalog.mp.microsoft.com/v7.0/products?bigIds=${id}&market=tr&languages=ru-ru`
    );

    dispatch(
      setSearchGamePriceTr(
        response.data.Products[0].DisplaySkuAvailabilities[0].Availabilities[0]
          .OrderManagementData.Price.ListPrice
      )
    );

    dispatch(
      setSearchGamePriceTrSale(
        response.data.Products[0]?.DisplaySkuAvailabilities[0]
          ?.Availabilities[1]?.OrderManagementData?.Price?.ListPrice ?? 0
      )
    );
  }
);

export const getSearchGamesInfoPriceUsa = createAsyncThunk(
  "SearchSlice/getSearchGamesInfoPriceUsa",

  async (id, { rejectWithValue, dispatch }) => {
    const response = await axios.get(
      `https://displaycatalog.mp.microsoft.com/v7.0/products?bigIds=${id}&market=us&languages=ru-ru`
    );

    dispatch(
      setSearchGamePriceUsa(
        response.data.Products[0].DisplaySkuAvailabilities[0].Availabilities[0]
          .OrderManagementData.Price.ListPrice
      )
    );

    dispatch(
      setSearchGamePriceUsaSale(
        response.data.Products[0]?.DisplaySkuAvailabilities[0]
          ?.Availabilities[1]?.OrderManagementData?.Price?.ListPrice ?? 0
      )
    );
  }
);

export const getSearchGamesInfoPriceUah = createAsyncThunk(
  "SearchSlice/getSearchGamesInfoPriceUah",

  async (id, { rejectWithValue, dispatch }) => {
    const response = await axios.get(
      `https://displaycatalog.mp.microsoft.com/v7.0/products?bigIds=${id}&market=ua&languages=ru-ru`
    );

    dispatch(
      setSearchGamePriceUah(
        response.data.Products[0].DisplaySkuAvailabilities[0].Availabilities[0]
          .OrderManagementData.Price.ListPrice
      )
    );

    dispatch(
      setSearchGamePriceUahSale(
        response.data.Products[0]?.DisplaySkuAvailabilities[0]
          ?.Availabilities[1]?.OrderManagementData?.Price?.ListPrice ?? 0
      )
    );
  }
);

// export const getSearchGamesInfoPriceEg = createAsyncThunk(
//   "SearchSlice/getSearchGamesInfoPriceEg",
//   //передаем ID при вызове функции на странице ,  , &page=${numberPage}
//   async (id, { rejectWithValue, dispatch }) => {
//     const response = await axios.get(
//       `https://displaycatalog.mp.microsoft.com/v7.0/products?bigIds=${id}&market=eg&languages=ru-ru`
//     );

//     dispatch(
//       setSearchGamePriceEg(
//         response.data.Products[0].DisplaySkuAvailabilities[0].Availabilities[0]
//           .OrderManagementData.Price.ListPrice
//       )
//     );

//     dispatch(
//       setSearchGamePriceEgSale(
//         response.data.Products[0]?.DisplaySkuAvailabilities[0]
//           ?.Availabilities[1]?.OrderManagementData?.Price?.ListPrice ?? 0
//       )
//     );
//   }
// );

// export const getSearchGamesInfoPriceNg = createAsyncThunk(
//   "SearchSlice/getSearchGamesInfoPriceNg",
//   //передаем ID при вызове функции на странице ,  , &page=${numberPage}
//   async (id, { rejectWithValue, dispatch }) => {
//     const response = await axios.get(
//       `https://displaycatalog.mp.microsoft.com/v7.0/products?bigIds=${id}&market=ng&languages=ru-ru`
//     );

//     dispatch(
//       setSearchGamePriceNg(
//         response.data.Products[0].DisplaySkuAvailabilities[0].Availabilities[0]
//           .OrderManagementData.Price.ListPrice
//       )
//     );

//     // console.log(
//     //   response.data.Products[0].DisplaySkuAvailabilities[0].Availabilities[0]
//     //     .OrderManagementData.Price.ListPrice
//     // );

//     dispatch(
//       setSearchGamePriceNgSale(
//         response.data.Products[0]?.DisplaySkuAvailabilities[0]
//           ?.Availabilities[1]?.OrderManagementData?.Price?.ListPrice ?? 0
//       )
//     );

//     // console.log(
//     //   response.data.Products[0]?.DisplaySkuAvailabilities[0]?.Availabilities[1]
//     //     ?.OrderManagementData?.Price?.ListPrice ?? 0
//     // );
//   }
// );

export const SearchSlice = createSlice({
  name: "SearchSlice",
  initialState,
  reducers: {
    //Записываем результат предлогаемого списка
    setSearchGamesList: (state, action) => {
      state.searchGamesList = action.payload;
    },

    setSearchGamesListEn: (state, action) => {
      state.searchGamesListEn = action.payload;
    },

    setSearchGameName: (state, action) => {
      state.searchGameName = action.payload;
    },
    setSearchGamePriceAr: (state, action) => {
      state.searchGamePriceAr = action.payload;
    },
    setSearchGamePriceTr: (state, action) => {
      state.searchGamePriceTr = action.payload;
    },
    setSearchGamePriceUsa: (state, action) => {
      state.searchGamePriceUsa = action.payload;
    },
    setSearchGamePriceUah: (state, action) => {
      state.searchGamePriceUah = action.payload;
    },
    // setSearchGamePriceEg: (state, action) => {
    //   state.searchGamePriceEg = action.payload;
    // },
    // setSearchGamePriceNg: (state, action) => {
    //   state.searchGamePriceNg = action.payload;
    // },
    // ============Цены на скидках
    setSearchGamePriceArSale: (state, action) => {
      state.searchGamePriceArSale = action.payload;
    },
    setSearchGamePriceTrSale: (state, action) => {
      state.searchGamePriceTrSale = action.payload;
    },
    setSearchGamePriceUsaSale: (state, action) => {
      state.searchGamePriceUsaSale = action.payload;
    },
    setSearchGamePriceUahSale: (state, action) => {
      state.searchGamePriceUahSale = action.payload;
    },
    // setSearchGamePriceEgSale: (state, action) => {
    //   state.searchGamePriceEgSale = action.payload;
    // },
    // setSearchGamePriceNgSale: (state, action) => {
    //   state.searchGamePriceNgSale = action.payload;
    // },
    // ============
    setSearchGameImg: (state, action) => {
      state.searchGameImg = action.payload;
    },

    setIsFetchingGamesInfo: (state, action) => {
      state.isFetchingGamesInfo = action.payload;
    },

    setIsFetchingPriceTr: (state, action) => {
      state.isFetchingPriceTr = action.payload;
    },

    setIsFetchingPriceUsa: (state, action) => {
      state.isFetchingPriceUsa = action.payload;
    },

    setIsFetchingPriceUah: (state, action) => {
      state.isFetchingPriceUah = action.payload;
    },

    setSearchGameDescription: (state, action) => {
      state.searchGameDescription = action.payload;
    },

    setSearchGameId: (state, action) => {
      state.searchGameId = action.payload;
    },

    setsearchProductType: (state, action) => {
      state.searchProductType = action.payload;
    },
    setWhatXbox: (state, action) => {
      state.whatXbox = action.payload;
    },
  },
  extraReducers: {
    //   Вызывается когда запрос прошел успешно
    [getSearchGamesList.fulfilled]: () => {
      // console.log("getSearchGamesList fullfiled");
    },

    //   Вызывается когда вызывается запрос
    [getSearchGamesList.pending]: (state) => {
      // console.log("getSearchGamesList pending");
      // console.log(state.searchGamesList);
    },
    //   Вызывается когда запрос прошел  не успешно
    [getSearchGamesList.rejected]: () => {
      // console.log("getSearchGamesList rejected");
    },

    //   Вызывается когда запрос прошел успешно
    [getSearchGamesListEn.fulfilled]: () => {
      // console.log("getSearchGamesListEn fullfiled");
    },

    //   Вызывается когда вызывается запрос
    [getSearchGamesListEn.pending]: () => {
      // console.log("getSearchGamesListEn pending");
    },
    //   Вызывается когда запрос прошел  не успешно
    [getSearchGamesListEn.rejected]: () => {
      // console.log("getSearchGamesListEn rejected");
    },

    //   Вызывается когда запрос прошел успешно
    [getSearchGamesInfo.fulfilled]: (state, action) => {
      // console.log("getSearchGamesInfo fullfiled");
      state.isFetchingGamesInfo = true;
    },
    //   Вызывается когда вызывается запрос
    [getSearchGamesInfo.pending]: (state, action) => {
      // console.log("getSearchGamesInfo pending");
      state.isFetchingGamesInfo = false;
    },
    //   Вызывается когда запрос прошел  не успешно
    [getSearchGamesInfo.rejected]: () => {
      // console.log("getSearchGamesInfo rejected");
    },

    //   Вызывается когда запрос прошел успешно
    [getSearchGamesInfoPriceTr.fulfilled]: (state, action) => {
      // console.log("getSearchGamesInfoPriceTr fullfiled");
      state.isFetchingPriceTr = true;
    },
    //   Вызывается когда вызывается запрос
    [getSearchGamesInfoPriceTr.pending]: (state, action) => {
      // console.log("getSearchGamesInfoPriceTr pending");
      state.isFetchingPriceTr = false;
    },
    //   Вызывается когда запрос прошел  не успешно
    [getSearchGamesInfoPriceTr.rejected]: (state) => {
      // console.log("getSearchGamesInfoPriceTr rejected");
    },

    ////////////////////////////
    //   Вызывается когда запрос прошел успешно
    [getSearchGamesInfoPriceUsa.fulfilled]: (state, action) => {
      // console.log("getSearchGamesInfoPriceUsa fullfiled");
      // state.isFetchingPriceTr = true;
      state.isFetchingPriceUsa = true;
    },
    //   Вызывается когда вызывается запрос
    [getSearchGamesInfoPriceUsa.pending]: (state, action) => {
      // console.log("getSearchGamesInfoPriceUsa pending");
      state.isFetchingPriceUsa = false;
      // state.isFetchingPriceTr = false;
    },
    //   Вызывается когда запрос прошел  не успешно
    [getSearchGamesInfoPriceUsa.rejected]: (state) => {
      // console.log("getSearchGamesInfoPriceUsa rejected");
    },

    ////////////////////////////////////////////

    ////////////////////////////
    //   Вызывается когда запрос прошел успешно
    [getSearchGamesInfoPriceUah.fulfilled]: (state, action) => {
      // console.log("getSearchGamesInfoPriceUsa fullfiled");
      // state.isFetchingPriceTr = true;
      state.isFetchingPriceUah = true;
    },
    //   Вызывается когда вызывается запрос
    [getSearchGamesInfoPriceUah.pending]: (state, action) => {
      // console.log("getSearchGamesInfoPriceUsa pending");
      state.isFetchingPriceUah = false;
      // state.isFetchingPriceTr = false;
    },
    //   Вызывается когда запрос прошел  не успешно
    [getSearchGamesInfoPriceUah.rejected]: (state) => {
      // console.log("getSearchGamesInfoPriceUsa rejected");
    },

    //   Вызывается когда запрос прошел успешно
    // [getSearchGamesInfoPriceEg.fulfilled]: (state, action) => {
    //   // console.log("getSearchGamesInfoPriceTr fullfiled");
    //   // state.isFetchingPriceTr = true;
    // },
    // //   Вызывается когда вызывается запрос
    // [getSearchGamesInfoPriceEg.pending]: (state, action) => {
    //   // console.log("getSearchGamesInfoPriceTr pending");
    //   // state.isFetchingPriceTr = false;
    // },
    // //   Вызывается когда запрос прошел  не успешно
    // [getSearchGamesInfoPriceEg.rejected]: (state) => {
    //   // console.log("getSearchGamesInfoPriceTr rejected");
    // },

    // //   Вызывается когда запрос прошел успешно
    // [getSearchGamesInfoPriceNg.fulfilled]: (state, action) => {
    //   // console.log("getSearchGamesInfoPriceTr fullfiled");
    //   // state.isFetchingPriceTr = true;
    // },
    // //   Вызывается когда вызывается запрос
    // [getSearchGamesInfoPriceNg.pending]: (state, action) => {
    //   // console.log("getSearchGamesInfoPriceTr pending");
    //   // state.isFetchingPriceTr = false;
    // },
    // //   Вызывается когда запрос прошел  не успешно
    // [getSearchGamesInfoPriceNg.rejected]: (state) => {
    //   // console.log("getSearchGamesInfoPriceTr rejected");
    // },
  },
});

export const {
  setSearchGamesList,
  setSearchGamesListEn,
  setSearchGameName,
  setSearchGamePriceAr,
  setSearchGamePriceTr,
  setSearchGamePriceUsa,
  setSearchGamePriceUah,
  // setSearchGamePriceEg,
  // setSearchGamePriceNg,
  setSearchGamePriceArSale,
  setSearchGamePriceTrSale,
  setSearchGamePriceUsaSale,
  setSearchGamePriceUahSale,
  // setSearchGamePriceEgSale,
  // setSearchGamePriceNgSale,
  setSearchGameImg,
  setIsFetchingGamesInfo,
  setIsFetchingPriceTr,
  setSearchGameDescription,
  setSearchGameId,
  setsearchProductType,
  setWhatXbox,
} = SearchSlice.actions;

export default SearchSlice.reducer;
